<template>
  <!-- 产品服务 -->
  <div class="product">
    <!-- <img :src="item.image" class="image" /> -->
    <div class="box_top">
      <img :src="container.coverPic"/>
    </div>
    <!-- 物流信息跟踪 -->
    <div class="infoTrackBox">
      <!--      <h3>{{ container.title }}</h3>-->
      <el-row :gutter="10" class="expressInput">
        <el-col :xs="20" :sm="18" :md="18" :lg="18" :xl="18">
          <div class="inputBox">
            <el-input v-model="waybillNum" :placeholder="langData['track.input.waybillNum']">
              <el-button slot="suffix" type="text" @click="getTrackInfo">
                {{ langData["track.btn.query"] }}
              </el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <!-- 物流盒子 -->
      <el-card class="logistics_Box" v-for="(item, index) in result" :key="index">
        <div slot="header" ref="headerImg" class="box_top " v-if="!item.errmsg">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <div>
                <strong>{{ langData["track.label.waybillNum"] }}：</strong>
                <p>{{ item.waybillNum }}</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="5" :xl="4">
              <div>
                <strong>{{ langData["track.label.des"] }}：</strong>
                <p>{{ item.toCountry }}</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="5" :xl="4">
              <div>
                <strong>{{ langData["track.label.status"] }}：</strong>
                <p>{{ item.statusText }}</p>
              </div>
            </el-col>
          </el-row>
        </div>

        <div v-if="item.errmsg">
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div style="display: flex">
                <strong>{{ langData["track.label.waybillNum"] }}：</strong>
                <p>{{ item.waybillNum }}</p>
                <p style="color: #da9597">&nbsp;&nbsp;{{ item.errmsg }}</p>
              </div>
            </el-col>
          </el-row>
        </div>

        <div v-else>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
              <div>
                <strong>{{ langData["track.label.detail"] }}:</strong>
              </div>
            </el-col>
            <el-col :xs="24" :sm="14" :md="15" :lg="15" :xl="7">
              <el-timeline>
                <el-timeline-item v-for="(subItem, index) in item.items" :color="subItem.color" :key="index"
                                  :timestamp="subItem.date">
                  <template v-if="subItem.location">[{{ subItem.location }}]</template>
                  {{ subItem.info }}
                </el-timeline-item>
              </el-timeline>
            </el-col>
            <el-col :xs="24" :sm="7" :md="6" :lg="6" :xl="7" class="qianshou">
              <div>
                <img :src="deliveredImg" class="statusImg" v-if="item.status == 10" alt=""/>
                <img :src="transitImg" class="statusImg" v-else alt=""/>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <div v-html="container.content"></div>
    </div>
  </div>
</template>
<script>
import axios from "../assets/axios/axios";
import {mapState} from "vuex";

export default {
  data() {
    return {
      // 运单编号
      waybillNum: "",
      result: [],
      deliveredImg: require("../assets/img/All/qianshou.png"),
      transitImg: require("../assets/img/All/yunshu.png"),
    };
  },
  computed: {
    ...mapState(["langData", "container"]),
  },
  created() {
    if (this.$route.query.waybillNum) {
      this.waybillNum = this.$route.query.waybillNum;
      this.getTrackInfo();
    }
    this.getTrackContent();
  },

  methods: {
    // 物流信息查询
    getTrackInfo() {
      axios.getTrackInfo(this.waybillNum).then((res) => {
        const data = res.data;
        this.result = data.result;
      });
    },
    // 获取内容
    getTrackContent() {
      axios.getTrackContent().then((res) => {
        const {data} = res.data;
        this.$store.commit("getContainer", data);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box_top {
  width: 100%;

  img {
    width: 100%;
  }
}

/* 物流时效透明化 */
.productBox {
  width: 100%;
  padding: 0 5% 3rem 5%;

  .el-row {
    margin: 3rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .inputBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-col {
    span {
      font-weight: bold;
      width: 6.25rem;
    }

    /deep/ .el-input {
      max-width: 20rem;

      .el-input__inner {
        height: 3rem;
      }

      .el-input__suffix {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
      }
    }

    .el-select {
      max-width: 20rem;
      height: 3rem;

      .el-input__inner {
        height: 3rem;
      }
    }
  }
}

/* 物流信息跟踪 */
.infoTrackBox {
  padding: 0 5% 3rem 5%;

  .expressInput {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
  }

  /deep/ .el-input {
    .el-input__inner {
      height: 3rem;
    }

    .el-button--text {
      font-size: 1.125rem;
      color: #fe9a02ed;
      margin-right: 1.25rem;
      font-weight: bold;
    }
  }

  /* 卡片盒子 */

  /deep/ .el-card {
    margin-bottom: 3rem;

    /* 卡片头部 */

    .el-card__header {
      position: relative;
      margin: 2rem 3rem;
      padding: 1rem 0 2rem 0;

      .el-col {
        div {
          display: flex;
        }
      }
    }

    /* 卡片主体 */

    .el-card__body {
      margin: 2rem 3rem;
      padding: 1rem 0 2rem 0;

      strong {
        /* margin-bottom: 1rem; */
      }

      .el-timeline {
        margin-top: 1rem;
      }

      .el-timeline-item__wrapper {
        /* display: flex; */

        .el-timeline-item__timestamp {
          margin-right: 1rem;
        }
      }

      .qianshou {
        float: right;
      }
    }

    .box_top {
    }
  }
}

@media screen and (max-width: 501px) {
  .statusImg {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .productBox {
    .inputBox {
      justify-content: space-between;

      margin-bottom: 1rem;

      /deep/ .el-input {
        width: 15.625rem !important;
      }

      .el-select {
        width: 15.625rem !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .el-card__body {
    margin: 2rem 3rem;
    padding: 1rem 0 2rem 0;

    /* .el-timeline-item__wrapper {
      display: inline-block !important;
    } */
  }
}
</style>